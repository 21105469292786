<template>
  <!-- 配置显示语言 -->
  <a-config-provider :locale="zhCN">
    <router-view />
  </a-config-provider>
</template>

<script>
// 国际化
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  data () {
    return {
      zhCN
    }
  },
  mounted () {
    // console.log(process.env.NODE_ENV, window.location.href)
  }
}
</script>

<style>
</style>