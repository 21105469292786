import router from '@/router'
import moment from 'moment'
import { message } from 'ant-design-vue'
import useClipboard from 'vue-clipboard3'

const Pub = {

  // 小程序图片地址
  appImageURL: (window.location.host.includes(':80')) ? 'http://video-admin.test.netjoy.com/djimage/' : 'https://duanju-public.oss-cn-hangzhou.aliyuncs.com/img/',

  // ================================== 《 项目运行环境 》

  IS_DEBUG() {
    const host = window.location.host
    if (host.includes('test') || host.includes('localhost')) {
      // 调试环境
      return true
    } else {
      // 线上环境
      return false
    }
  },

  // 蓝鲸项目
  ljProjectIDs() {
    return this.IS_DEBUG() ? [18, '18'] : [2, '2']
  },

  // 蓝鲸项目
  ljProjectID() {
    return this.IS_DEBUG() ? 18 : 2
  },

  // 九霄项目
  jxProjectID() {
    return this.IS_DEBUG() ? 28 : 15
  },

  // 当前项目ID
  projectID() {
    return this.CUSTOM('project')
  },

  // ================================= 《 阿 里 云 bucket 》

  // 阿里云/火山云视频获取指定帧作为预览图（参考：https://blog.csdn.net/guo_qiangqiang/article/details/108865279）
  // t: 指定截图时间（单位 sm）
  // w: 指定截图宽度，0 自动计算（单位 px）
  // h: 指定截图高度，0 自动计算（单位 px）
  // f: 指定输出图片格式（jpg、png）
  // m: 指定截图模式（'', fast）
  // ar: 是否根据视频信息自动旋转图片（'', auto）
  VIDEO_COVER({ url, t = 1000, w = 0, h = 0, f = 'jpg', m = 'fast', ar = 'auto' }) {
    var str = `${url}?x-oss-process=video/snapshot,t_${t},w_${w},h_${h},f_${f}`
    if (m) { str += `,m_${m}` }
    if (ar) { str += `,ar_${ar}` }
    return str
  },

  // 当前上传平台切换：al(阿里)、hs(火山)
  uploadType: 'hs',

  // 文件地址CDN
  CDN_URL(url) {
    if (url) {
      if (url.includes(process.env.VUE_APP_OSS_PRI)) {
        return this.CDN_URL_PRI(url.replace(process.env.VUE_APP_OSS_PRI, ''))
      } else if (url.includes(process.env.VUE_APP_OSS_PRI_TEST)) {
        return this.CDN_URL_PRI(url.replace(process.env.VUE_APP_OSS_PRI_TEST, ''))
      } else if (url.includes(process.env.VUE_APP_OSS_PUB)) {
        return this.CDN_URL_PUB(url.replace(process.env.VUE_APP_OSS_PUB, ''))
      } else if (url.includes(process.env.VUE_APP_OSS_PUB_TEST)) {
        return this.CDN_URL_PUB(url.replace(process.env.VUE_APP_OSS_PUB_TEST, ''))
      } else if (url.includes(process.env.VUE_APP_OSS_PRI_HS)) {
        return this.CDN_URL_PRI(url.replace(process.env.VUE_APP_OSS_PRI_HS, ''))
      } else if (url.includes(process.env.VUE_APP_OSS_PRI_TEST_HS)) {
        return this.CDN_URL_PRI(url.replace(process.env.VUE_APP_OSS_PRI_TEST_HS, ''))
      } else if (url.includes(process.env.VUE_APP_OSS_PUB_HS)) {
        return this.CDN_URL_PUB(url.replace(process.env.VUE_APP_OSS_PUB_HS, ''))
      } else if (url.includes(process.env.VUE_APP_OSS_PUB_TEST_HS)) {
        return this.CDN_URL_PUB(url.replace(process.env.VUE_APP_OSS_PUB_TEST_HS, ''))
      }
    }
    return url
  },

  // 私有文件地址CDN
  CDN_URL_PRI(path) {
    var url = ''
    if (this.uploadType === 'al') {
      url = this.IS_DEBUG() ? process.env.VUE_APP_OSS_PRI_TEST : process.env.VUE_APP_OSS_PRI
    } else if (Pub.uploadType === 'hs') {
      url = this.IS_DEBUG() ? process.env.VUE_APP_OSS_PRI_TEST_HS : process.env.VUE_APP_OSS_PRI_HS
    }
    return `${url}${path}`
  },

  // 公有文件地址CDN
  CDN_URL_PUB(path) {
    var url = ''
    if (this.uploadType === 'al') {
      url = this.IS_DEBUG() ? process.env.VUE_APP_OSS_PUB_TEST : process.env.VUE_APP_OSS_PUB
    } else if (Pub.uploadType === 'hs') {
      url = this.IS_DEBUG() ? process.env.VUE_APP_OSS_PUB_TEST_HS : process.env.VUE_APP_OSS_PUB_HS
    }
    return `${url}${path}`
  },

  // 私有
  BUCKET_PRI() {
    return (this.IS_DEBUG() ? process.env.VUE_APP_BUCKET_PRI_TEST : process.env.VUE_APP_BUCKET_PRI)
  },

  // 公有
  BUCKET_PUB() {
    return (this.IS_DEBUG() ? process.env.VUE_APP_BUCKET_PUB_TEST : process.env.VUE_APP_BUCKET_PUB)
  },

  // 穿透域名
  PROXY_HOST() {
    // return (this.IS_DEBUG() ? 'https://test-duanju-proxy.netjoy.com' : 'https://duanju.netjoy.com')
    return (this.IS_DEBUG() ? 'https://test-duanju-proxy.netjoy.com' : 'https://landing.juhaokanya.com')
  },

  // 推广落地页 isLanding ：当存在需要落地页同时也需要小程序原始路由时用于区分
  LANDING_URL(record, isLanding = true) {
    // 是否为 iaa
    const isIaa = record.type === 'iaa'
    // 落地页地址
    const landingURL = `${this.PROXY_HOST()}/landing.html`
    // 参数
    let params = `uid=${record.uid}${record.project_drama_id ? `&id=${record.project_drama_id}&eq=${record.eq_number}` : ''}`
    // 抖音小程序单独处理
    if (record.client == 2) {
      // 拼接官方指定要求的绑定字段
      params = `${params}${record.tt_album_id ? `&tt_album_id=${record.tt_album_id}` : ''}${record.tt_episode_id ? `&tt_episode_id=${record.tt_episode_id}` : ''}`
    }
    // 快手小程序单独处理
    if (record.client == 3) {
      // 拼接官方指定要求的绑定字段
      params = `${params}${record.ks_drama_id ? `&ksPlayletId=${record.ks_drama_id}` : ''}${record.ks_episode_id ? `&ksEpisodeId=${record.ks_episode_id}` : ''}${record.eq_number ? `&ksSeq=${record.eq_number}` : ''}`
    }
    // const other = `${landingURL}?${params}&source=other`
    const notSupport = '暂不支持该推广方式'
    // 是否为测试环境
    const isDebug = this.IS_DEBUG()
    // 返回推广链接规则
    switch (record.channel) {
      // 巨量
      case 1:
        // 客户端
        switch (record.client) {
          // 微信小程序
          case 1:
            // 参数
            const params11 = `${params}&source=juliang_wx`
            // 区分回传方式
            if (record.channel_type === 2) {
              // 资产化 1.0
              return `/pages/index/index?${params11}&clue_token=___&ad_id=___&creative_id=___&req_id=___&advertiser_id=___`
            } else if (record.channel_type === 4) {
              // 资产化 2.0
              return `/pages/index/index?${params11}&clue_token=___&project_id=___&promotion_id=___&mid1=___&mid2=___&mid3=___&mid4=___&mid5=___&req_id=___&advertiser_id=___`
            } else if (record.channel_type === 5) {
              // 星图
              return `/pages/index/index?${params11}&clickid=___&item_id=___`
            } else {
              // 非资产化 1.0 2.0
              // 通过橙子落地页解析uid，并获取scheme跳转微信小程序
              return `${landingURL}?${params11}&adid=__AID__&creativeid=__CID__&clickid=__CLICKID__`
            }
          // 抖音小程序
          case 2:
            if (isIaa) {
              // 参数
              const params12 = `${params}&source=iaa`
              // 区分调起方式
              if (record.channel_type === 1) {
                // H5调起
                return `${landingURL}?${params12}`
              } else if (record.channel_type === 2) {
                // 组件调起
                // 要落地页还是原始路由
                if (!isLanding) {
                  // 抖音小程序文档要求链接不要以 / 开头
                  // 分割的参数不要以 ? 或 & 开头
                  return `pages/index/index?${params12}`
                } else {
                  return `${landingURL}?${params12}`
                }
              }
            } else {
              // 参数
              const params12 = `${params}&source=juliang_tt`
              // 要落地页还是原始路由
              if (!isLanding) {
                // 抖音小程序文档要求链接不要以 / 开头
                // 分割的参数不要以 ? 或 & 开头
                return `pages/index/index?${params12}`
              } else {
                return `${landingURL}?${params12}`
              }
            }
          // 快应用
          case 4:
            // 参数
            const params14 = `${params}&source=juliang_kyy`
            // 页面路径
            const path14 = `/pages/tabbar?${params14}`
            // 路径
            return `hap://app/com.lanjing.module${path14}`
          // H5（微信客服链接）
          case 5:
            return record.remark ? record.remark.qy_wechat_kfurl : '未生成客服链接'
          default:
            return notSupport
        }
      // 广点通
      case 3:
        // 参数
        const params3 = `${params}&source=gdt`
        // 客户端
        switch (record.client) {
          // 微信小程序
          case 1:
            if (isIaa) {
              if (isIaa) {
                // 参数
                const params31 = `${params}&click_id=__CLICK_ID__&source=iaa_gdt`
                // 区分调起方式
                if (record.channel_type === 1) {
                  // H5调起
                  return `${landingURL}?${params31}`
                } else if (record.channel_type === 2) {
                // 组件调起
                  // 要落地页还是原始路由
                  if (!isLanding) {
                    // 抖音小程序文档要求链接不要以 / 开头
                    // 分割的参数不要以 ? 或 & 开头
                    return `pages/index/index?${params31}`
                  } else {
                    return `${landingURL}?${params31}`
                  }
                }
              } else {
                // 区分回传方式
                if (record.channel_type === 1) {
                  // H5调起
                  return `${landingURL}?${params3}&adid=__AID__&creativeid=__CID__`
                } else if (record.channel_type === 2) {
                  // 组件调起
                  const path = `/pages/index/index?${params3}`
                  return path
                } else if (record.channel_type === 3) {
                  // 企业微信
                  const path = `/pages/index/index?${params3}&clickid=${record.remark.click_id}`
                  return path
                } else {
                  return notSupport
                }
              }
            } else {
              // 区分回传方式
              if (record.channel_type === 1) {
                // H5调起
                return `${landingURL}?${params3}&adid=__AID__&creativeid=__CID__`
              } else if (record.channel_type === 2) {
                // 组件调起
                const path = `/pages/index/index?${params3}`
                return path
              } else if (record.channel_type === 3) {
                // 企业微信
                const path = `/pages/index/index?${params3}&clickid=${record.remark.click_id}`
                return path
              }
            }
          default:
            return notSupport
        }
      // 其他
      case 4:
        // 参数
        const params4 = `${params}&source=other`
        // 客户端
        switch (record.client) {
          // 微信小程序
          case 1:
            if (isIaa) {
              // 参数
              const params41 = `${params}&source=iaa_other`
              // 区分调起方式
              if (record.channel_type === 1) {
                // H5调起
                return `${landingURL}?${params41}`
              } else if (record.channel_type === 2) {
                // 组件调起
                // 要落地页还是原始路由
                if (!isLanding) {
                  // 抖音小程序文档要求链接不要以 / 开头
                  // 分割的参数不要以 ? 或 & 开头
                  return `pages/index/index?${params41}`
                } else {
                  return `${landingURL}?${params41}`
                }
              }
            } else {
              // 非IAA
              return `${landingURL}?${params4}`
            }
          // 抖音小程序
          case 2:
            if (isIaa) {
              // 参数
              const params42 = `${params}&source=iaa_other`
              // 区分调起方式
              if (record.channel_type === 1) {
                // H5调起
                return `${landingURL}?${params42}`
              } else if (record.channel_type === 2) {
                // 组件调起
                // 要落地页还是原始路由
                if (!isLanding) {
                  // 抖音小程序文档要求链接不要以 / 开头
                  // 分割的参数不要以 ? 或 & 开头
                  return `pages/index/index?${params42}`
                } else {
                  return `${landingURL}?${params42}`
                }
              }
            } else {
              // 非IAA
              return `${landingURL}?${params4}`
            }
          // H5
          case 5:
            // 页面路径
            const path45 = `/pages/index/index?${params4}`
            // 根据环境进行返回
            if (isDebug) {
              return `https://test-m.juhaokanya.com${path45}`
            } else {
              return `https://m.juhaokanya.com${path45}`
            }
          default:
            return notSupport
        }
      // OPPO
      case 5:
        // 参数
        const params5 = `${params}&source=oppo`
        // 客户端
        switch (record.client) {
          // 微信小程序
          case 1:
            // 区分回传方式
            if (record.channel_type === 1) {
              // H5调起
              return `${landingURL}?${params5}`
            } else if (record.channel_type === 2) {
              // 组件调起
              const path = `/pages/index/index?${params5}`
              return path
            }
          // 快应用
          case 4:
            // 页面路径
            const path54 = `/pages/tabbar?${params5}`
            // 路径
            return `hap://app/com.lanjing.module${path54}`
          // H5
          case 5:
            // 页面路径
            const path55 = `/pages/index/index?${params5}`
            // 根据环境进行返回
            if (isDebug) {
              return `https://test-m.juhaokanya.com${path55}`
            } else {
              return `https://m.juhaokanya.com${path55}`
            }
          default:
            return notSupport
        }
      // 推啊
      case 6:
        // 页面路径
        const path2 = `/platforms/h5/tuia/index?uid=${record.uid}&source=tuia`
        // 客户端
        switch (record.client) {
          // H5
          case 5:
            if (isDebug) {
              return `https://test-m.juhaokanya.com${path2}`
            } else {
              return `https://m.juhaokanya.com${path2}`
            }
          default:
            return notSupport
        }
      // VIVO
      case 7:
        // 参数
        const params7 = `${params}&source=vivo&adid=__ADID__&requestid=__REQUESTID__`
        // 客户端
        switch (record.client) {
          // 微信小程序
          case 1:
            return `${landingURL}?${params7}`
          // 快应用
          case 4:
            return `hap://app/com.lanjing.module?${params7}`
          default:
            return notSupport
        }
      // 磁力引擎
      case 8:
        // 参数
        const params8 = `${params}&source=kuaishou`
        // 客户端
        switch (record.client) {
          // 微信小程序
          case 1:
            // 区分回传方式
            if (record.channel_type === 1) {
              // H5调起
              return `${landingURL}?${params8}&adid=__AID__&creativeid=__CID__`
            } else if (record.channel_type === 2) {
              // 组件调起
              const path = `/pages/index/index?${params8}`
              return path
            }
          // 快手小程序
          case 3:
            // 组件调起
            const path = `/pages/index/index?${params8}`
            return path
          default:
            return notSupport
        }
      // 爱奇艺
      case 9:
        // 参数
        const params9 = `${params}&source=iqiyi`
        // 客户端
        switch (record.client) {
          // 微信小程序
          case 1:
            // 组件调起
            const path = `miniAppName=${record.remark.gh_id}&miniAppPath=/pages/index/index?${params9}`
            return path
          // // 区分回传方式
          // if (record.channel_type === 1) {
          //   // H5调起
          //   return `${landingURL}?${params9}`
          // } else if (record.channel_type === 2) {
          //   // 组件调起
          //   const path = `/pages/index/index?${params9}`
          //   return path
          // }
          default:
            return notSupport
        }
      // 百度
      case 10:
        // 参数
        const params10 = `${params}&source=baidu`
        // 客户端
        switch (record.client) {
          // 微信小程序
          case 1:
            // 组件调起
            const path = `/pages/index/index?${params10}`
            return path
          // return `${landingURL}?${params10}`
          default:
            return notSupport
        }
      // 达人
      case 11:
        // 参数
        const params11 = `${params}&source=daren`
        // 客户端
        switch (record.client) {
          // 微信
          case 1:
            // 页面路径
            return `/pages/index/index?${params11}&appid=${record.appid}`
          // 抖音
          case 2:
            // 页面路径
            return `/pages/index/index?${params11}&appid=${record.appid}`
          // 快手
          case 3:
            // 页面路径
            return `/pages/index/index?${params11}&appid=${record.appid}`
          // H5
          case 5:
            // 页面路径
            const path55 = `/pages/index/index?${params11}&appid=${record.appid}`
            // 根据环境进行返回
            if (isDebug) {
              return `https://test-m.juhaokanya.com${path55}`
            } else {
              return `https://m.juhaokanya.com${path55}`
            }
          default:
            return notSupport
        }
      // 小米
      case 12:
        // 参数
        const params12 = `${params}&source=xiaomi`
        // 客户端
        switch (record.client) {
          // 微信小程序
          case 1:
            return `${landingURL}?${params12}`
          default:
            return notSupport
        }
      // 优酷
      case 13:
        // 参数
        const params13 = `${params}&source=youku`
        // 客户端
        switch (record.client) {
          // 微信小程序
          case 1:
            if (!isLanding) {
              return `/pages/index/index?${params13}&adv_callback_id=__TRACKID__&creativeid=__CREATIVEID__`
            } else {
              return `${landingURL}?${params13}`
            }
          default:
            return notSupport
        }
      // B站
      case 14:
        // 参数
        const params14 = `${params}&source=bili&adv_callback_id=__TRACKID__`
        // 客户端
        switch (record.client) {
          // 微信小程序
          case 1:
            // 区分回传方式
            if (record.channel_type === 1) {
              // H5调起
              return `${landingURL}?${params14}`
            } else if (record.channel_type === 2) {
              // 组件调起
              return `pages/index/index?${params14}`
            }
          default:
            return notSupport
        }
      // 微博
      case 15:
        // 参数
        const params15 = `${params}&source=weibo`
        // 客户端
        switch (record.client) {
          // 微信小程序
          case 1:
            // 区分回传方式
            if (record.channel_type === 1) {
              // H5调起
              return `${landingURL}?${params15}`
            } else if (record.channel_type === 2) {
              // 组件调起
              return `pages/index/index?${params15}`
            }
          default:
            return notSupport
        }
      // WIfi万能钥匙
      case 16:
        // 参数
        const params16 = `${params}&source=wifi`
        // 客户端
        switch (record.client) {
          // 微信小程序
          case 1:
            // 区分回传方式
            if (record.channel_type === 1) {
              // H5调起
              return `${landingURL}?${params16}`
            } else if (record.channel_type === 2) {
              // 组件调起
              return `pages/index/index?${params16}`
            }
          default:
            return notSupport
        }
      // UC
      case 17:
        // 参数
        const params17 = `${params}&source=uc`
        // 客户端
        switch (record.client) {
          // 微信小程序
          case 1:
            // 区分回传方式
            if (record.channel_type === 1) {
              // H5调起
              return `${landingURL}?${params17}`
            } else if (record.channel_type === 2) {
              // 组件调起
              return `pages/index/index?${params17}`
            }
          default:
            return notSupport
        }
      // 默认
      default:
        return notSupport
    }
  },

  // 推广失败落地页
  FAIL_URL(record) {
    // 落地页地址
    const landingURL = `${this.PROXY_HOST()}/landing.html`
    // 参数
    let params = `uid=${record.uid}${record.project_drama_id ? `&id=${record.project_drama_id}&eq=${record.eq_number}` : ''}`
    // 是否为测试环境
    // const isDebug = this.IS_DEBUG()
    // 返回推广链接规则
    switch (record.channel) {
      // OPPO
      case 5:
        // 参数
        const params5 = `${params}&source=oppo`
        // 客户端
        switch (record.client) {
          // 快应用
          case 4:
            // 路径
            return `${landingURL}?${params5}&package=com.lanjing.module`
          default:
            return '暂未开放推广'
        }
      // 默认
      default:
        return '暂未开放推广'
    }
  },

  // 生产环境-拥有【项目配置-项目管理】权限的账号数组
  SPECIAL_UIDS() {
    return [1, 2, 3]
  },

  // 是否是特殊账号
  IS_SPECIAL_UIDS() {
    const uid = this.USERINFO() ? JSON.parse(this.USERINFO()).id : ''
    return this.SPECIAL_UIDS().includes(uid)
  },

  // ================================= 《 路 由 跳 转 》

  // 新窗口访问链接
  OPEN_URL(url) {
    window.open(url, '_blank')
  },

  // 新窗口访问路由地址
  OPEN_ROUTER(path, query) {
    const routeData = router.resolve({
      path: path,
      query: query
    })
    window.open(routeData.href, '_blank')
  },

  // 跳转到指定地址
  JUMP_URL(url) {
    window.location = url
  },

  // 跳转到指定路由地址
  JUMP_ROUTER(path, query) {
    router.push({
      path: path,
      query: query
    })
  },

  // 当前域名
  DOMAIN_NAME(path) {
    return window.location.protocol + '//' + window.location.host + (path || '')
  },

  // ================================= 《 正 则 效 验 》

  // 正则匹配是否存在
  REG_TEST(reg, value) {
    var re = new RegExp(reg)
    if (re.test(value)) {
      return true
    } else {
      return false
    }
  },

  // 删除字符串全部空格
  STRING_SPACE_ALL(str) {
    return str.replace(/\s/g, '')
  },

  // 删除字符串左右空格
  STRING_SPACE_LR(str) {
    return str.replace(/(^\s*)|(\s*$)/g, '')
  },

  // 删除字符串左边空格
  STRING_SPACE_L(str) {
    return str.replace(/(^\s*)/g, '')
  },

  // 删除字符串右边空格
  STRING_SPACE_R(str) {
    return str.replace(/(\s*$)/g, '')
  },

  // 判断是否为正数（允许小数点，不能为负数）
  REG_IS_NUMBER(value) {
    return (this.REG_TEST(/^\d+(\.\d+)?$/, value))
  },

  // 判断是否为正整数 或 最多2位的浮点数
  REG_IS_INTEGER_OR_FLOAT(value) {
    return (this.REG_TEST(/^\d+(?:\.\d{1,2})?$/, value))
  },

  // 判断是否为 正整数或 0
  REG_IS_INTEGER(value) {
    return (this.REG_TEST(/^\d+$/, value))
  },

  // 判断是否为正整数
  REG_IS_POSITIVE_INTEGER(value) {
    return (this.REG_TEST(/(^[1-9]\d*$)/, value))
  },

  // 判断是否为手机号
  REG_IS_PHONE(value) {
    return (this.REG_TEST(/^1[3456789]\d{9}$/, value))
  },

  // 判断是否为邮箱
  REG_IS_EMAIL(value) {
    return (this.REG_TEST(/^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/, value))
  },

  // 判断是否是偶数
  IS_EVEN_NUMBER(number) {
    return (number % 2 == 0)
  },

  // 判断是否为6-12位字母数字组合
  REG_IS_PASSWORD(value) {
    return (this.REG_TEST(/^[\w]{6,12}$/, value))
  },

  // 生成千级（1000-9999）、万级（10000-99999）、十万级（100000-999999）、百万级（1000000-9999999）的随机整数
  // level参数对应： 1000、10000、100000、1000000
  RANDOM(level) {
    // 可取最小值
    const min = level;
    // 可取最大值
    const max = level * 10 - 1;
    // 生成最小值到最大值之间的随机整数
    const num = Math.floor(Math.random() * (max - min + 1)) + min;
    return num
  },

  // 效验正整数
  VALIDATOR_POSITIVE_INTEGER(rule, value) {
    if (value == undefined || value == null) {
      return Promise.reject('请输入')
    } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
      return Promise.reject('请输入正整数')
    }
    return Promise.resolve()
  },

  // ================================= 《 小 数 点 处 理 》

  // 检查小数点是否超过指定个数 true: 超过 false：没超过
  CHECK_NUMBER_DECIMAL(value, maxLength) {
    // 转为字符串
    var valueString = `${(value || '')}`
    // 小数长度
    var decimalLength = 0
    // 是否存在小数点
    if (valueString.includes('.')) {
      // 获取小数长度
      decimalLength = valueString.split('.')[1].length
    }
    return decimalLength > maxLength
  },

  // 保留小数点位数
  // value: 数值，支持字符串
  // decimal：保留小数点位数
  // isNumber：是否转为 Number，默认 String
  // isComplete：小数点不够时，是否用 0 尾部进行补全
  // completeMax：补全最大数限制，0：按实际补全，也就是小数点差几位补几位
  KEEP_NUMBER_DECIMAL(value, decimal, isNumber, isComplete, completeMax = 0) {
    // 字符串
    var valueString = `${value || 0}`
    // 保留小数点位数
    var decimalCount = Math.max(0, decimal)
    // 补全数量
    var completeMaxCount = Math.max(0, completeMax)
    // 数字
    var numberString = valueString
    var decimalString = ''
    // 是否存在小数点
    if (valueString.includes('.')) {
      // 分割
      var strs = valueString.split('.')
      // 记录
      numberString = strs[0]
      decimalString = strs[1]
    }
    // 分割小数点
    if (decimalString.length > decimalCount) {
      // 截取小数点
      decimalString = decimalString.substring(0, decimalCount)
    }
    // 小数位数不够，是否用 0 补全
    if (isComplete && decimalString.length < decimalCount) {
      // 补全位数
      var completeCount = decimalCount - decimalString.length
      // 检查限制
      if (completeMaxCount) { completeCount = Math.min(completeMaxCount, completeCount) }
      // 进行补全
      if (completeCount) { for (let index = 0; index < completeCount; index++) { decimalString += '0' } }
    }
    // 有小数
    if (decimalString.length) {
      // 组合
      numberString += `.${decimalString}`
    }
    // 返回
    return isNumber ? Number(numberString) : numberString
  },

  // ================================= 《 文 件 路 径 处 理 》

  // 是否为图片
  FILE_IS_IMAGE(filePath, isFuzzy = true) {
    // 后缀列表（如果缺少自行补充）
    const types = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff']
    // 返回匹配结果
    return this.FILE_IS_TYPE(filePath, types, isFuzzy)
  },

  // 是否为视频
  FILE_IS_VIDEO(filePath, isFuzzy = true) {
    // 后缀列表（如果缺少自行补充）
    const types = ['mp4', 'mp3', 'avi', 'wmv', 'mpg', 'mpeg', 'mov', 'rm', 'ram', 'swf', 'flv', 'wma', 'avi', 'rmvb', 'mkv']
    // 返回匹配结果
    return this.FILE_IS_TYPE(filePath, types, isFuzzy)
  },

  // 检查文件后缀是否为存在指定格式列表中（isFuzzy：如果正常匹配失败，是否允许使用模糊匹配二次匹配）
  FILE_IS_TYPE(filePath, types, isFuzzy = true) {
    // 匹配结果
    var isResult = false
    // 路径有值 && 格式列表有值
    if (filePath && filePath.length && types.length) {
      // 文件后缀
      const type = this.FILE_EXTENSION(filePath)
      // 精确匹配
      isResult = types.indexOf(type.toLowerCase()) !== -1
      // 匹配失败 && 允许模糊匹配
      if (!isResult && isFuzzy) {
        // 匹配是否存在
        types.some(item => {
          // 匹配规则
          var reg = new RegExp(`\\.${item}\\?`, 'i')
          // 匹配结果
          var results = filePath.match(reg) || []
          // 取得结果
          isResult = Boolean(results.length)
          // 返回
          return isResult
        })
      }
    }
    // 返回
    return isResult
  },

  // 获取路径后缀（不带 '.'）
  FILE_EXTENSION(filePath) {
    // 后缀类型
    var type = ''
    // 路径有值
    if (filePath && filePath.length) {
      // 获取路径中最后一个 '.' 位置
      var index = filePath.lastIndexOf('.')
      // 截取尾部后缀
      type = filePath.substr(index + 1)
    }
    // 返回
    return type
  },

  // ================================= 《 JSON 快 捷 取 值 》

  // 获取指定 key 值
  VALUE(obj, key) {
    // 当前值
    var value = undefined
    // 是否有值
    if (obj && key) {
      // 赋值
      value = obj
      // 分析大括号
      if (key.includes('[') || key.includes(']')) {
        // 替换符号
        if (key.includes('[')) {
          key = key.replace(new RegExp('\\[', "gm"), '.')
          key = key.replace(new RegExp('\\]', "gm"), '')
        } else {
          key = key.replace(new RegExp('\\]', "gm"), '.')
        }
      }
      // 拆分
      const keys = key.split('.')
      // 过滤出来可用的 keys
      const newKeys = []
      // 过滤
      keys.forEach(itemKey => {
        // 有值则添加
        if (itemKey.length) { newKeys.push(itemKey) }
      })
      // 取值
      newKeys.some(itemKey => {
        // 直接取值
        if (value) { value = value[itemKey] }
        // 是否停止
        return !value
      })
    }
    // 返回
    return value
  },

  // ================================= 《 时 间 日 期 》

  // 时间转字符串（今天，昨天，xx月xx日，xxxx年xx月xx日）
  // calendars：用于覆盖配置
  DATE_STRING(dateString, format = 'YYYY-MM-DD HH:mm:ss', calendars = {}) {
    // 配置格式
    moment.updateLocale('zh-cn', {
      calendar: {
        // 格式：[中文内容] YYYY-MM-DD HH:mm:ss
        // dddd：星期几
        // nextDay: '[明天]',
        // sameDay: '[今天]',
        // lastDay: '[昨天]',
        // nextWeek: '[下周] dddd',
        // lastWeek: '[上周] dddd',
        // sameElse: 'YYYY-MM-DD HH:mm',
        nextDay: '[明天] HH:mm',
        sameDay: '[今天] HH:mm',
        lastDay: '[昨天] HH:mm',
        nextWeek: 'dddd HH:mm',
        lastWeek: '[上周] dddd HH:mm',
        sameElse: 'YYYY-MM-DD HH:mm',
        ...calendars
      }
    })
    // 获取结果
    const string = moment(dateString, format).calendar()
    // 返回结果
    return string
  },

  // 指定的日期时间是否 <= 今天
  DATE_COMPARE_TODAY(dateString, format = 'YYYY-MM-DD HH:mm:ss') {
    // 将日期转换为 date 格式
    const date = moment(dateString, format).format('YYYYMMDD')
    // 获取今天 date 格式
    const toDay = moment().format('YYYYMMDD')
    // 返回比较结果
    return date <= toDay
  },

  // 对比两个数组，根据指定 key返回两个数组的并集（且根据 key去重）
  mergeArr(arr1, arr2, key) {
    // 拼接
    let arr = arr1.concat(arr2)
    // 去重
    return this.unique(arr, key)
  },

  // 数组对象根据指定的 key 去重
  unique(arr, key) {
    // map 存放 key对应的数据
    const res = new Map()
    const target = arr.filter((item) => !res.has(item[key]) && res.set(item[key], 1))
    // 深拷贝后返回，防止响应式造成数据影响
    return this.deepClone(target)
  },

  // 定义一个深拷贝函数  接收目标target参数
  deepClone(target) {
    // 定义一个变量
    let result;
    // 如果当前需要深拷贝的是一个对象的话
    if (typeof target === 'object') {
      // 如果是一个数组的话
      if (Array.isArray(target)) {
        result = []; // 将result赋值为一个数组，并且执行遍历
        for (let i in target) {
          // 递归克隆数组中的每一项
          result.push(this.deepClone(target[i]))
        }
        // 判断如果当前的值是null的话；直接赋值为null
      } else if (target === null) {
        result = null;
        // 判断如果当前的值是一个RegExp对象的话，直接赋值    
      } else if (target.constructor === RegExp) {
        result = target;
      } else {
        // 否则是普通对象，直接for in循环，递归赋值对象的所有值
        result = {};
        for (let i in target) {
          result[i] = this.deepClone(target[i]);
        }
      }
      // 如果不是对象的话，就是基本数据类型，那么直接赋值
    } else {
      result = target;
    }
    // 返回最终结果
    return result;
  },

  // ================================= 《 formdata 》

  // 将参数转换成功 formdata 接收格式
  FORM_DATA(data) {
    const formData = new FormData()
    for (const key in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(key)) {
        if (data[key]) {
          if (data[key].constructor === Array) {
            if (data[key][0]) {
              if (data[key][0].constructor === Object) {
                formData.append(key, JSON.stringify(data[key]))
              } else {
                data[key].forEach((item, index) => {
                  formData.append(key + `[${index}]`, item)
                })
              }
            } else {
              formData.append(key + '[]', '')
            }
          } else if (data[key].constructor === Object) {
            formData.append(key, JSON.stringify(data[key]))
          } else {
            formData.append(key, data[key])
          }
        } else {
          if (data[key] === 0) {
            formData.append(key, 0)
          } else {
            formData.append(key, '')
          }
        }
      }
    }
    return formData
  },

  // ================================= 《 拷 贝 》

  // 复制方法
  COPY: async (str) => {
    try {
      // 复制
      await useClipboard().toClipboard(str)
      // 复制成功
      message.success('拷贝成功')
    } catch (e) {
      // 复制失败
      message.error('拷贝失败')
    }
  },

  // ================================= 《 针 对 项 目 自 定 义 》

  // 获取唯一标识符
  GetUniqueID() {
    // 当前时间转成 36 进制字符串
    var time = Date.now().toString(36)
    // 当前随机数转成 36 进制字符串
    var random = Math.random().toString(36)
    // 去除随机数的 0. 字符串
    random = random.substring(2, random.length)
    // 返回唯一ID
    return random + time
  },

  // (获取 || 设置) token
  ACCESS_TOKEN(token) {
    if (token === undefined) {
      return localStorage.getItem('token')
    } else {
      return localStorage.setItem('token', token)
    }
  },

  // (获取 || 设置) userinfo
  USERINFO(userinfo) {
    if (userinfo === undefined) {
      return localStorage.getItem('userinfo')
    } else {
      return localStorage.setItem('userinfo', userinfo)
    }
  },

  // (获取 || 设置) 自定义字段
  CUSTOM(key, value) {
    if (value === undefined) {
      return localStorage.getItem(key)
    } else {
      return localStorage.setItem(key, value)
    }
  },

  // 退出登录
  LOGOUT() {
    // 清空 token
    this.ACCESS_TOKEN('')
    this.USERINFO('')
    // 进入登录
    this.JUMP_ROUTER('/login')
  }
}

// 导出
export default Pub