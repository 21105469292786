<template>
  <div class="header-view">
    <!-- 菜单 -->
    <a-menu
      v-model:selectedKeys="selectedKeys"
      theme="dark"
      mode="horizontal"
      @select="menuSelect"
    >
      <!-- 根据是否有下拉菜单展示 -->
      <template v-for="item in platforms" :key="item.path">
        <!-- 无下拉菜单 -->
        <a-menu-item
          :key="item.path"
          v-if="!item.meta.hasDropdown"
        >
          {{ item.meta.title }}
        </a-menu-item>
        <!-- 有下拉菜单，并且下拉菜单有数据才展示（目前只有：运营中心——下拉项目列表） -->
        <a-sub-menu
          :title="item.meta.title"
          v-if="item.meta.hasDropdown && dropdownList[item.meta.dropdownOption].length"
        >
          <!-- 项目列表 -->
          <a-menu-item
            :parentKey="item.path"
            v-for="i in dropdownList[item.meta.dropdownOption]"
            :key="'$' + item.meta.dropdownOption + '-' + i.id"
            :subType="item.meta.dropdownOption"
          >
            {{ i.name }}
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
    <!-- 弹簧 -->
    <div style="flex: 1;"></div>
    <!-- 下拉菜单 -->
    <a-dropdown :trigger="['click']">
      <div class="info-view">
        <!-- 头像 -->
        <img class="info-avatar" :src="require('@/assets/img/avatar.svg')">
        <!-- 信息 -->
        <div class="corporate-info">
          <div class="corporate-name">{{ userinfo.username || userinfo.phone }}</div>
        </div>
        <!-- 箭头 -->
        <caret-down-outlined class="info-arrow"  />
      </div>
      <template #overlay>
        <a-menu>
          <!-- 退出登录 -->
          <a-menu-item @click="touchModify">
            <form-outlined /> 修改密码
          </a-menu-item>
          <!-- 退出登录 -->
          <a-menu-item @click="touchLogout">
            <export-outlined class="operation-icon" /> 退出登录
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
  <!-- 修改密码 -->
  <ModifyPwdModal ref="RefModifyPwdModal"></ModifyPwdModal>
</template>

<script setup>
// 导入
import { onBeforeMount, ref, reactive } from 'vue'
import { useRoute } from 'vue-router'
import Pub from '@/utils/public'
import { projectList } from '@/api/operate'
import { message, Modal } from 'ant-design-vue'
import ModifyPwdModal from './ModifyPwdModal.vue'

// 页面属性
var selectedKeys = ref([])
// 修改密码
let RefModifyPwdModal = ref(null)
// props
const props = defineProps({
  // 平台列表
  platforms: {
    type: Array,
    default: []
  }
})

// emit
const emit = defineEmits(['select'])

// 用户信息
const userinfo = JSON.parse(Pub.USERINFO() || '{}')

// 下拉列表
let dropdownList = reactive({
  project: []
})

// 生命周期
onBeforeMount(() => {
  // 获得当前平台
  const key = useRoute().matched[0].path
  // 选中平台
  const meta = useRoute().matched[0].meta
  if (meta.hasDropdown) {
    // 有下拉菜单：处理为一级菜单下拉的选中
    const type = meta.dropdownOption
    selectedKeys.value = [`$${type}-${Pub.CUSTOM(type)}`]
  } else {
    // 无下拉菜单直接选中
    selectedKeys.value = [key]
  }
  // 初始化子菜单
  emit('select', key)
  // 获取项目列表
  getProjectList()
})

// 获取成员项目列表
function getProjectList () {
  projectList().then(res => {
    const { code, data, msg } = res
    if (code === 0) {
      dropdownList.project = data
      // 当前账号下项目列表为空，并且访问运营中心下的页面时，跳转管理中心的首页
      if (!data.length && Pub.CUSTOM('MAIN_PATH').includes('/operate')) {
        Pub.JUMP_URL(location.origin + '/copyright-home')
      }
    } else {
      message.error(res.message || msg)
    }
  })
}

// 菜单选中
function menuSelect ({ item, key, selectedKeys }) {
  // 处理一级菜单下拉选中（ 运营中心 - 项目）
  if (item.subType) {
    // 修改缓存里面 subType 对应的值, 以 '-' 分割
    Pub.CUSTOM(item.subType, key.split('-')[1])
    // 刷新子菜单
    emit('select', item.parentKey)
    // 跳转路由
    Pub.JUMP_URL(location.origin + item.parentKey)
  }
  // 一级菜单选中
  else {
    // 刷新子菜单
    emit('select', key)
    // 跳转路由
    Pub.JUMP_URL(location.origin + key)
  }
}

// 退出
function touchLogout () {
  Pub.LOGOUT()
}

// 修改密码
function touchModify () {
  RefModifyPwdModal.value.showModal()
}
</script>

<style lang="less" scoped>
.header-view {
  display: flex;
  background-color: #001529;
  .ant-menu {
    min-width: 700px;
  }
  /deep/ .ant-menu-submenu-selected {
    // 有子菜单的选中，也处理为主题色
    &:not(.ant-menu-item-only-child) {
      background-color: #1890ff;
    }
  }
}
.info-view {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 48px;
}
.info-view .info-avatar {
  display: inline-block;
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 100%;
}
.info-view .info-arrow {
  margin-left: 8px;
  color: #fff;
}
.corporate-info {
  margin-left: 10px;
}
.corporate-name {
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  color: #fff;
}
.operation-logout {
  color: #E34D59;
}
.operation-icon {
  color: #E62412;
  font-size: 14px !important;
}
</style>