import axios from '@/api/axios'

// 服务器代理地址
const BASE_URL = process.env.VUE_APP_BASE_URL

// ================================= 《 公 共 》

// 获取七牛token
// export function getUploadToken (parameter) {
//   return axios({
//     url: BASE_URL + '/qiniu/get_token',
//     method: 'get',
//     params: parameter
//   })
// }

// 获取阿里云token
export function getUploadToken(parameter) {
  return axios({
    url: BASE_URL + '/oss/get_token',
    method: 'get',
    params: parameter
  })
}

// 获取火山云token
export function getUploadHuoShanToken(parameter) {
  return axios({
    url: BASE_URL + '/oss/get_huoshan_token',
    method: 'get',
    params: parameter
  })
}

// 用户权限菜单
export function menusPermissions(parameter) {
  return axios({
    url: BASE_URL + '/user/menus',
    method: 'post',
    data: parameter
  })
}

// 推广代理商权限菜单
export function agentMenusPermissions(parameter) {
  return axios({
    url: BASE_URL + '/agent/agent_menus',
    method: 'get',
    data: parameter
  })
}

// 修改密码
export function updatePassword(parameter) {
  return axios({
    url: BASE_URL + `/user/update_password`,
    method: 'post',
    data: parameter
  })
}

// ---------------------------------------- 《权限管理》

/**
 * 权限列表
 */
export function permissionList(parameter) {
  return axios({
    url: BASE_URL + `/permission/list/${parameter.source}`,
    method: 'get',
    params: parameter
  })
}

/**
 * 权限搜索
 */
export function permissionSearch(parameter) {
  return axios({
    url: BASE_URL + `/permission/search/${parameter.source}`,
    method: 'get',
    params: parameter
  })
}

/**
 * 权限新增
 */
export function permissionStore(parameter) {
  return axios({
    url: BASE_URL + `/permission/edit/${parameter.source}`,
    method: 'post',
    data: parameter
  })
}

/**
 * 权限更新
 */
export function permissionUpdate(parameter) {
  return axios({
    url: BASE_URL + `/permission/edit/${parameter.source}`,
    method: 'post',
    data: parameter
  })
}

/**
 * 权限删除
 */
export function permissionDelete(parameter) {
  return axios({
    url: BASE_URL + `/permission/delete`,
    method: 'post',
    data: parameter
  })
}

// ====================================《 账户成员（管理中心、运营中心共用） 》

// 成员列表
export function userList(parameter) {
  return axios({
    url: BASE_URL + `/user/user_list`,
    method: 'get',
    params: parameter
  })
}

// 新增\修改成员
export function userEdit(parameter) {
  return axios({
    url: BASE_URL + `/user/save_user`,
    method: 'post',
    data: parameter
  })
}

// 删除成员
export function userDelete(parameter) {
  return axios({
    url: BASE_URL + `/user/delete_user`,
    method: 'post',
    data: parameter
  })
}

// 成员-菜单权限详情
export function userMenuList(parameter) {
  return axios({
    url: BASE_URL + `/menu/menu_list`,
    method: 'get',
    params: parameter
  })
}

// 成员-菜单权限编辑
export function userMenuSave(parameter) {
  return axios({
    url: BASE_URL + `/menu/menu_save`,
    method: 'post',
    data: parameter
  })
}