import router from '@/router'
import Pub from '@/utils/public'
import { setDocumentTitle, domTitle } from '@/utils/domUtil'
import { menusPermissions } from '@/api/request'
import { message } from 'ant-design-vue'

// 白名单
const whiteList = ['/login', '/check']

// 权限列表
var permissions = []

// 首页白名单
let whiteListPro = [
  '/copyright',
  '/copyright-home',
  '/operate',
  '/operate-home'
]
// 内部账户使用 - 项目管理
if (Pub.IS_DEBUG() || Pub.IS_SPECIAL_UIDS()) {
  whiteListPro = whiteListPro.concat([
    '/copyright-project',
    '/copyright-projects',
    '/copyright-projects-config'
  ])
}

// 路由守卫
router.beforeEach((to, from, next) => {
  // 根路径
  const mainPath = to.matched[0].path
  // 存储页面地址
  Pub.CUSTOM('MAIN_PATH', mainPath)
  // 个人信息数据
  const user = JSON.parse(Pub.USERINFO() || '{}')
  // 是否为代理推广账户
  if (user.project_agent_id > 0) {
    if (mainPath.includes('/promotion')) {
      // 有权限，继续
      next()
    } else {
      // 提示（因为在进入代理商管理中心的时候走到这报无权限）
      if (to.path !== '/copyright-home') {
        message.error('暂无访问权限')
      }
      // 无权限，进首页
      next({ path: '/promotion' })
    }
  } else {
    // 检查是否有权限列表
    if (permissions.length || !Pub.ACCESS_TOKEN()) {
      // 继续
      beforeEachHandler(to, from, next, mainPath)
    } else {
      // 请求权限列表
      getMenusPermissions(mainPath, (isOK) => {
        // 继续
        beforeEachHandler(to, from, next, mainPath)
      })
    }
  }
})

// 跳转处理
function beforeEachHandler(to, from, next, mainPath) {
  // title
  to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${domTitle}`))
  // 默认
  // next()
  // 检查是否有 Token
  if (Pub.ACCESS_TOKEN()) {
    // 判断权限
    if (permissions.includes(to.path) || whiteListPro.includes(to.path)) {
      // 有权限，继续
      next()
    } else {
      // 提示
      message.error('暂无访问权限')
      // 无权限，进首页
      if (mainPath.includes('/promotion')) {
        next({ path: '/copyright' })
      } else {
        next({ path: mainPath })
      }
    }
  } else {
    // 没有 Token，检查白名单
    if (whiteList.includes(to.path)) {
      // 属于白名单则继续下一步
      next()
    } else {
      // 不属于白名单则进入登录页面（redirect 值可作为登录成功后跳转的地址，帮助用户进入指定页面）
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  }
}

// 获取权限列表
function getMenusPermissions(path, result) {
  // 刷新当前选中项目
  const project = path.includes('/operate') ? Pub.CUSTOM('project') : undefined
  // 请求权限
  menusPermissions({ project_id: project }).then(res => {
    const { code, data } = res
    if (code === 0) {
      // 记录权限
      permissions = data.permissions
    }
    if (result) { result(code === 0) }
  }).catch(() => {
    if (result) { result(false) }
  })
}

// 获取页面类型
function getModuleType(path) {
  // 判断类型
  if (path.includes('copyright')) {
    // 管理中心
    return 'copyright'
  } else if (path.includes('/operate')) {
    // 运营中心
    return 'operate'
  } else {
    // 未知
    return ''
  }
}