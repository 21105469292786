<template>
  <!-- 主视图 -->
  <a-layout id="layout-main">
    <!-- 左侧菜单 -->
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
      <!-- logo -->
      <div class="layout-logo">
        <!-- 通用logo -->
        <img :src="require('@/assets/img/logo_common.svg')" v-if="!['1', '2', '3'].includes(project)">
        <!-- 各项目对应的logo -->
        <img :src="require('@/assets/img/logo_' + project + '.svg')" v-else>
      </div>
      <!-- 中间内容菜单 -->
      <Menu
        ref="MenuView"
        class="layout-menu"
        :collapsed="collapsed"
        :menus="menus"
      ></Menu>
    </a-layout-sider>
    <!-- 右侧内容 -->
    <a-layout class="layout-sub">
      <!-- 右侧头部内容 -->
      <a-layout-header class="layout-header">
        <!-- 展开收起 -->
        <!-- <menu-unfold-outlined
          v-if="collapsed"
          class="layout-trigger"
          @click="() => (collapsed = !collapsed)"
        />
        <menu-fold-outlined
          v-else
          class="layout-trigger"
          @click="() => (collapsed = !collapsed)"
        /> -->
        <HeaderView :platforms="platformRoutes" @select="reloadPlatformRoutes"></HeaderView>
      </a-layout-header>
      <!-- 右侧底部内容 -->
      <a-layout-content class="layout-content" :class="isNoBackground ? 'custom-content' : ''">
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import Pub from '@/utils/public'
import Menu from './components/Menu'
import HeaderView from './components/HeaderView'
import { copyrightRoutes, operateRoutes, promotionRoutes } from '@/router/config'
import { menusPermissions, agentMenusPermissions } from '@/api/request'
export default {
  components: {
    Menu,
    HeaderView
  },
  data() {
    return {
      collapsed: false,
      // 当前菜单路由
      menus: [],
      // 平台路由
      platformRoutes: [],
      // 用户个人信息
      user: {},
      // 运营中心当前选中的项目
      project: undefined,
      // 无背景色的 layout-content
      isNoBackground: false,
      // 白名单列表(不参与权限判断处理，如果是大菜单路由可直接代替所有子菜单，如果是子菜单需单独一个一个列出来)
      whiteRoutes: [
        '/copyright-home',
        '/operate-home',
      ],
      // 权限列表
      permissions: []
    }
  },
  // 监听路由变化
  watch: {
    // 监听路由
    $route: {
      handler (newData) {
        // 不需要背景色的路由集合
        const paths = ['/operate-home']
        this.isNoBackground = paths.includes(newData.path) ? true : false
      },
      immediate: true
    }
  },
  created () {
    // 个人信息数据
    this.user = JSON.parse(Pub.USERINFO() || '{}')
    // 是否为代理推广账户
    if (this.user.project_agent_id > 0) {
      // 组装平台路由
      this.platformRoutes = promotionRoutes
      // 如果当前路径不是推广代理商的路径，进入推广代理商默认页面
      if (!this.$route.path.includes('/promotion')) {
        // 跳转
        Pub.JUMP_ROUTER('/promotion')
      }
    } else {
      // 手动添加【项目管理】权限
      if (Pub.IS_DEBUG() || Pub.IS_SPECIAL_UIDS()) {
        this.whiteRoutes.push('/copyright-project')
      }
      // 组装平台路由
      this.platformRoutes = copyrightRoutes.concat(operateRoutes)
    }
  },
  mounted () {
    // 获取权限列表
    this.getMenusPermissions()
  },
  methods: {
    // 获取权限列表
    getMenusPermissions () {
      // 主路径
      const path = Pub.CUSTOM('MAIN_PATH')
      // 判断是否为 推广代理
      if (path.includes('/promotion')) {
        // 请求权限
        agentMenusPermissions().then(res => {
          const { code, data } = res
          if (code === 0) {
            // 记录权限
            this.permissions = data.permissions
            // 刷新菜单
            this.updateMenus(path)
          }
        })
      } else {
        // 刷新当前选中项目
        this.project = path.includes('/operate') ? Pub.CUSTOM('project') : undefined
        // 请求权限
        menusPermissions({ project_id: this.project }).then(res => {
          const { code, data } = res
          if (code === 0) {
            // 记录权限
            this.permissions = data.permissions
            // 刷新菜单
            this.updateMenus(path)
          }
        })
      }
    },
    // 刷新平台路由展示
    reloadPlatformRoutes (key) {
      // // 获取权限列表
      // if (key.includes('copyright')) {
      //   // 管理中心
      // } else {
      //   // 运营中心
      // }
      // // 刷新菜单列表
      // this.updateMenus(key)
    },
    // 根据权限重组菜单列表
    updateMenus (key) {
      // 旧版本使用
      // // 刷新当前选中项目
      // this.project = key.includes('/operate') ? Pub.CUSTOM('project') : undefined
      // // 获取菜单列表
      // this.menus = this.platformRoutes.find(item => item.path === key).children
      // // 刷新菜单展开
      // setTimeout(() => { this.$refs['MenuView'].reloadOpenKeys() }, 200)

      // 新版本使用
      // 刷新当前选中项目
      // this.project = key.includes('/operate') ? Pub.CUSTOM('project') : undefined
      // 获取菜单列表
      const menus = this.platformRoutes.find(item => item.path === key).children
      // 菜单权限处理
      menus.forEach(route => {
        // 一级菜单
        this.setupRoutes(route)
        // 如果有子菜单还处于显示状态，则一级菜单也需要显示
        const children = route.children || []
        // 如果没有子菜单说明它就是一级页面，默认展示，目前只有首页
        if (children.length) {
          const isHidden = children.every(item => {
            return !!item.hidden
          })
          route.hidden = isHidden
        }
      })
      // 定制化处理
      menus.forEach(route => {
        // 对应页面定制化处理
        if (route.path === '/promotion-settlement') {
          route.hidden = !this.permissions.includes(route.path)
        } else if (route.path === '/copyright-project') {
          const children = route.children || []
          if (children.length) {
            children.forEach(item => {
              if (item.path === '/copyright-projects-config') {
                if (Pub.IS_DEBUG()) {
                  // 测试都可见
                  item.hidden = false
                } else {
                  // 只有用户id === 3 的可见
                  item.hidden = ![3].includes(this.user.id)
                }
              }
            })
          }
        }
      })
      // 显示
      this.menus = menus
    },
    // 处理菜单权限
    setupRoutes (route, superHidden) {
      // 是否为白名单
      if (this.whiteRoutes.includes(route.path)) { return }
      // 路由权限添加
      if (!route.hidden) {
        // 是否为推广代理
        if (route.path.includes('/promotion')) {
          // 显示
          route.hidden = false
        } else {
          // 是否包含
          route.hidden = !this.permissions.includes(route.path)
        }
      }
      // 子菜单
      const children = route.children || []
      // 便利处理
      children.forEach(route => { this.setupRoutes(route) })
    }
  }
}
</script>
<style>
#layout-main {
  min-height: 100%;
}
#layout-main .layout-sub {
  overflow: hidden;
}
#layout-main .layout-trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
#layout-main .layout-trigger:hover {
  color: #1890ff;
}
#layout-main .layout-logo {
  height: 32px;
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#layout-main .layout-logo img{
  display: block;
  max-width: 100%;
  max-height: 100%;
}
#layout-main .layout-menu {
  width: 100%;
}
#layout-main .layout-header {
  background: #fff;
  padding: 0;
}
#layout-main .layout-content {
  margin: 24px 16px;
  padding: 24px;
  background: #fff;
  min-height: 280px;
}
#layout-main .custom-content {
  background: inherit;
  padding: 10px;
}
</style>
