import { createRouter, createWebHistory } from 'vue-router'
import { routes, copyrightRoutes, operateRoutes, promotionRoutes } from '@/router/config'

const rs = routes.concat(copyrightRoutes).concat(operateRoutes).concat(promotionRoutes)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: rs
})

export default router
