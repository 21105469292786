// 初始化相关
import { createApp, nextTick } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 路由守卫
import '@/utils/permission'

// global less
import '@/assets/css/common.css'

// 公用属性对象
import Pub from '@/utils/public'

// 日期工具
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

// Antd
import Antd from 'ant-design-vue'
import * as Icons from '@ant-design/icons-vue'
import 'ant-design-vue/dist/antd.css'
import 'ant-design-vue/dist/antd.less'

// 友盟
import { init } from '@umengfe/apm'
if (!Pub.IS_DEBUG()) {
  init({
    pageFilter: { mode: 'ignore', rules: [] },
    pid: '65571e0eb2f6fa00ba817824'
  })
}

// 创建对象
const app = createApp(App)
// 使用并挂载
app.use(store).use(router).use(Antd).mount('#app')
// 必须使用 nextTick，不然会有加载顺序问题，导致绑定失败
nextTick(() => {
  // 配置全局对象
  app.config.globalProperties.$pub = Pub
  // 为了支持动态 icon 使用
  app.config.globalProperties.$icons = Icons
  app.config.globalProperties.$moment = moment
  // Antd 注入全部图标（为了不需要每个页面导入 icon 使用）
  for (const key in Icons) { app.component(key, Icons[key]) }
})
// 导出
// export default app
