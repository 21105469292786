import axios from 'axios'
import Pub from '@/utils/public'
import { message } from 'ant-design-vue'

// 创建 axios 实例
const service = axios.create({
  // api
  baseURL: '',
  // 请求超时时间
  timeout: 120000
})

// 将 post 请求的 data 数据转换成 formdata 格式（请求数据设置为 params 不会被转换）
// FormData：https://blog.csdn.net/zz00008888/article/details/113933885
// service.defaults.transformRequest = data => {
//   return Pub.FORM_DATA(data)
// }

// 添加请求拦截器
service.interceptors.request.use(config => {
  // 在发送请求之前做些什么
  // 设置 Token 数据
  const token = Pub.ACCESS_TOKEN()
  if (token) {
    // token
    config.headers['Authorization'] = token
    // 项目id (只有在运营中心的时候需要)
    config.headers['project-id'] = (Pub.CUSTOM('MAIN_PATH') || '').includes('/operate') ? Pub.CUSTOM('project') : 0
  }
  return config
})

// 添加响应拦截器
service.interceptors.response.use(response => {
  // 对响应数据做点什么
  const res = response.data
  // token 失效
  const tokenErrorCode = [1001, 1002, 1003, 1004, 1005]
  if (tokenErrorCode.includes(res.code)) {
    // 提示
    message.error(res.message)
    // 进入登录
    Pub.LOGOUT()
  }
  // 返回数据
  return res
})

// 导出
export default service
