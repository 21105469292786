<template>
  <a-modal
    title="修改密码"
    :visible="visible"
    :confirm-loading="isLoading"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <!-- 表单数据 -->
    <a-form
      name="form"
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 18 }"
    >
      <!-- 新密码 -->
      <a-form-item
        label="新密码"
        name="pwd"
        :rules="[{ required: true, validator: validatorPassword }]"
      >
        <a-input v-model:value="formState.pwd" :disabled="isLoading" placeholder="请输入" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import { updatePassword } from '@/api/request'
import md5 from 'js-md5'
import Pub from '@/utils/public'

// 注册父组件的事件
const emit = defineEmits('success')
// 表单实例
let RefForm = ref(null)
// 是否展示弹窗
let visible = ref(false)
// 表单数据
let formState = reactive({
  pwd: undefined
})
// 加载
let isLoading = ref(false)

// 展示弹窗
function showModal () {
  visible.value = true
}

// 关闭弹窗
function handleCancel () {
  // 重置表单
  RefForm.value.resetFields()
  visible.value = false
}

// 提交
function handleOk () {
  // 手动触发表单验证
  RefForm.value.validate().then(() => {
    isLoading.value = true
    updatePassword({
      password: md5(formState.pwd)
    }).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('修改成功')
        // 更新父组件
        emit('success')
        // 关闭弹窗
        handleCancel()
      } else {
        message.error(res.message || msg)
      }
    }).catch(() => {
      isLoading.value = false
      message.error('修改失败')
    })
  })
}

// 效验 - 密码
function validatorPassword (rule, value) {
  if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_PASSWORD(value)) {
    return Promise.reject('请输入6-12位仅包含字母或数字的密码')
  }
  return Promise.resolve()
}

defineExpose({
  showModal
})

</script>

<style lang="less" scoped>

</style>